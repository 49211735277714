define("discourse/plugins/discourse-teambuild/discourse-teambuild-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("teamBuild", {
      path: "/team-build"
    }, function () {
      this.route("index", {
        path: "/"
      });
      this.route("progress");
      this.route("manage");
      this.route("show", {
        path: "/progress/:username"
      });
    });
  }
});