define("discourse/plugins/discourse-teambuild/discourse/templates/components/teambuild-choice", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="teambuild-choice {{if this.completed 'completed' 'incomplete'}}">
    <div class="controls">
      {{#if this.readOnly}}
        {{#if this.completed}}
          {{d-icon "check"}}
        {{/if}}
      {{else}}
        {{#if this.completed}}
          <DButton
            @icon="check"
            @action={{action "undo"}}
            class="btn-primary"
            @title="discourse_teambuild.progress.mark_incomplete"
          />
        {{else}}
          <DButton
            @icon="circle"
            @action={{action "complete"}}
            @title="discourse_teambuild.progress.mark_complete"
          />
        {{/if}}
      {{/if}}
    </div>
    <div class="choice-label">{{this.label}}</div>
  </div>
  */
  {
    "id": "LiKF1GLp",
    "block": "[[[10,0],[15,0,[29,[\"teambuild-choice \",[52,[30,0,[\"completed\"]],\"completed\",\"incomplete\"]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n\"],[41,[30,0,[\"readOnly\"]],[[[41,[30,0,[\"completed\"]],[[[1,\"        \"],[1,[28,[35,1],[\"check\"],null]],[1,\"\\n\"]],[]],null]],[]],[[[41,[30,0,[\"completed\"]],[[[1,\"        \"],[8,[39,2],[[24,0,\"btn-primary\"]],[[\"@icon\",\"@action\",\"@title\"],[\"check\",[28,[37,3],[[30,0],\"undo\"],null],\"discourse_teambuild.progress.mark_incomplete\"]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,2],null,[[\"@icon\",\"@action\",\"@title\"],[\"circle\",[28,[37,3],[[30,0],\"complete\"],null],\"discourse_teambuild.progress.mark_complete\"]],null],[1,\"\\n\"]],[]]]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"choice-label\"],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n\"],[13]],[],false,[\"if\",\"d-icon\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-teambuild/discourse/templates/components/teambuild-choice.hbs",
    "isStrictMode": false
  });
});