define("discourse/plugins/discourse-teambuild/discourse/models/teambuild-progress", ["exports", "discourse/lib/ajax-error", "discourse/models/rest"], function (_exports, _ajaxError, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function choiceKey(target, userId) {
    return `${target.id}:${userId}`;
  }
  class TeambuildProgress extends _rest.default {
    isComplete(target, userId) {
      return this.completed.includes(choiceKey(target, userId));
    }
    complete(target, userId) {
      target.complete(userId).then(() => {
        this.completed.addObject(choiceKey(target, userId));
      }).catch(_ajaxError.popupAjaxError);
    }
    undo(target, userId) {
      target.undo(userId).then(() => {
        this.completed.removeObject(choiceKey(target, userId));
      }).catch(_ajaxError.popupAjaxError);
    }
  }
  _exports.default = TeambuildProgress;
});