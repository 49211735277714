define("discourse/plugins/discourse-teambuild/discourse/templates/team-build-manage", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="teambuild-manage">
    {{#if this.targets}}
      {{#each this.sortedTargets as |target idx|}}
        <TeambuildTarget
          @target={{target}}
          @groups={{this.groups}}
          @index={{idx}}
          @length={{this.sortedTargets.length}}
          @removeTarget={{action "removeTarget" target}}
          @moveUp={{action "move" idx -1}}
          @moveDown={{action "move" idx 1}}
        />
      {{/each}}
    {{else}}
      <div class="get-started">
        {{i18n "discourse_teambuild.manage.get_started"}}
      </div>
    {{/if}}
  
    <div class="controls">
      <DButton
        class="btn-primary create-target"
        @label="discourse_teambuild.targets.create"
        @icon="plus"
        @action={{action "newTarget"}}
      />
    </div>
  </div>
  */
  {
    "id": "FhjFHo++",
    "block": "[[[10,0],[14,0,\"teambuild-manage\"],[12],[1,\"\\n\"],[41,[30,0,[\"targets\"]],[[[42,[28,[37,2],[[28,[37,2],[[30,0,[\"sortedTargets\"]]],null]],null],null,[[[1,\"      \"],[8,[39,3],null,[[\"@target\",\"@groups\",\"@index\",\"@length\",\"@removeTarget\",\"@moveUp\",\"@moveDown\"],[[30,1],[30,0,[\"groups\"]],[30,2],[30,0,[\"sortedTargets\",\"length\"]],[28,[37,4],[[30,0],\"removeTarget\",[30,1]],null],[28,[37,4],[[30,0],\"move\",[30,2],-1],null],[28,[37,4],[[30,0],\"move\",[30,2],1],null]]],null],[1,\"\\n\"]],[1,2]],null]],[]],[[[1,\"    \"],[10,0],[14,0,\"get-started\"],[12],[1,\"\\n      \"],[1,[28,[35,5],[\"discourse_teambuild.manage.get_started\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[8,[39,6],[[24,0,\"btn-primary create-target\"]],[[\"@label\",\"@icon\",\"@action\"],[\"discourse_teambuild.targets.create\",\"plus\",[28,[37,4],[[30,0],\"newTarget\"],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"target\",\"idx\"],false,[\"if\",\"each\",\"-track-array\",\"teambuild-target\",\"action\",\"i18n\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-teambuild/discourse/templates/team-build-manage.hbs",
    "isStrictMode": false
  });
});