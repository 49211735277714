define("discourse/plugins/discourse-teambuild/discourse/adapters/teambuild-target", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TeambuildTargetAdapter extends _rest.default {
    jsonMode = true;
    pathFor(store, type, id) {
      if (id) {
        return `/team-build/targets/${id}.json`;
      }
      return `/team-build/targets.json`;
    }
  }
  _exports.default = TeambuildTargetAdapter;
});