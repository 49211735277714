define("discourse/plugins/discourse-teambuild/components/teambuild-target", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/string", "discourse/lib/ajax-error", "discourse/mixins/buffered-content", "discourse/plugins/discourse-teambuild/discourse/models/teambuild-target"], function (_exports, _component, _object, _computed, _string, _ajaxError, _bufferedContent, _teambuildTarget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend((0, _bufferedContent.bufferedProperty)("target"), {
    tagName: "",
    editSelected: false,
    needsGroup: (0, _computed.equal)("buffered.target_type_id", _teambuildTarget.Types.USER_GROUP),
    canMoveUp: (0, _object.computed)("editing", "index", function () {
      return !this.editing && this.index > 0;
    }),
    canMoveDown: (0, _object.computed)("editing", "index", "length", function () {
      return !this.editing && this.index < this.length - 1;
    }),
    editing: (0, _computed.or)("editSelected", "target.isNew"),
    targetTypes: (0, _object.computed)(function () {
      return Object.keys(_teambuildTarget.Types).map(key => {
        return {
          id: _teambuildTarget.Types[key],
          name: (0, _string.underscore)(key)
        };
      });
    }),
    saveDisabled: (0, _object.computed)("buffered.name", "target.isSaving", "needsGroup", "buffered.group_id", function () {
      if (this.target.isSaving) {
        return true;
      }
      let name = this.get("buffered.name");
      if (!name || name.length === 0) {
        return true;
      }
      if (this.needsGroup && !this.get("buffered.group_id")) {
        return true;
      }
      return false;
    }),
    actions: {
      save() {
        this.target.save(this.buffered.getProperties("name", "target_type_id", "group_id")).then(() => {
          this.set("editSelected", false);
        }).catch(_ajaxError.popupAjaxError);
      },
      cancel() {
        if (this.target.isNew) {
          return this.removeTarget();
        } else {
          this.set("editSelected", false);
          this.rollbackBuffer();
        }
      },
      destroy() {
        this.target.destroyRecord().then(() => this.removeTarget());
      }
    }
  });
});