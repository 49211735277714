define("discourse/plugins/discourse-teambuild/discourse/routes/team-build-show", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TeamBuildShowRoute extends _route.default {
    model(params) {
      return this.store.find("teambuild-progress", params.username);
    }
    setupController(controller, progress) {
      this.controllerFor("teamBuild.progress").setProperties({
        progress
      });
    }
    renderTemplate() {
      this.render("teamBuild.progress");
    }
  }
  _exports.default = TeamBuildShowRoute;
});