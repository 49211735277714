define("discourse/plugins/discourse-teambuild/discourse/controllers/team-build-progress", ["exports", "@ember/controller", "discourse/lib/computed"], function (_exports, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TeamBuildProgressController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "readOnly", [(0, _computed.propertyNotEqual)("currentUser.id", "progress.user.id")]))();
    #readOnly = (() => (dt7948.i(this, "readOnly"), void 0))();
  }
  _exports.default = TeamBuildProgressController;
});