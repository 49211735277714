define("discourse/plugins/discourse-teambuild/discourse/routes/team-build-index", ["exports", "@ember/routing/route", "discourse/lib/ajax"], function (_exports, _route, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TeamBuildIndexRoute extends _route.default {
    model() {
      return (0, _ajax.ajax)("/team-build/scores.json");
    }
    setupController(controller, model) {
      controller.set("scores", model.scores);
    }
  }
  _exports.default = TeamBuildIndexRoute;
});