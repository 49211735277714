define("discourse/plugins/discourse-teambuild/components/teambuild-choice", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component"], function (_exports, _component, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TeambuildChoice = dt7948.c(class TeambuildChoice extends _component.default {
    get completed() {
      return this.progress.isComplete(this.target, this.userId);
    }
    static #_ = (() => dt7948.n(this.prototype, "completed", [(0, _object.computed)("progress.completed.[]")]))();
    complete() {
      this.progress.complete(this.target, this.userId);
    }
    static #_2 = (() => dt7948.n(this.prototype, "complete", [_object.action]))();
    undo() {
      this.progress.undo(this.target, this.userId);
    }
    static #_3 = (() => dt7948.n(this.prototype, "undo", [_object.action]))();
  }, [(0, _component2.tagName)("")]);
  var _default = _exports.default = TeambuildChoice;
});