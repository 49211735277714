define("discourse/plugins/discourse-teambuild/discourse/controllers/team-build-manage", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "discourse/plugins/discourse-teambuild/discourse/models/teambuild-target"], function (_exports, _controller, _object, _computed, _teambuildTarget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TeamBuildManageController extends _controller.default {
    targets = null;
    targetSort = ["position"];
    static #_ = (() => dt7948.g(this.prototype, "sortedTargets", [(0, _computed.sort)("targets", "targetSort")]))();
    #sortedTargets = (() => (dt7948.i(this, "sortedTargets"), void 0))();
    move(idx, direction) {
      let item = this.sortedTargets[idx];
      let other = this.sortedTargets[idx + direction];
      if (item && other) {
        item.swapPosition(other);
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "move", [_object.action]))();
    newTarget() {
      let maxPosition = 0;
      if (this.targets.length > 0) {
        maxPosition = Math.max(...this.targets.map(t => t.position));
      }
      this.targets.pushObject(this.store.createRecord("teambuild-target", {
        target_type_id: _teambuildTarget.Types.REGULAR,
        position: maxPosition + 1
      }));
    }
    static #_3 = (() => dt7948.n(this.prototype, "newTarget", [_object.action]))();
    removeTarget(t) {
      this.targets.removeObject(t);
    }
    static #_4 = (() => dt7948.n(this.prototype, "removeTarget", [_object.action]))();
  }
  _exports.default = TeamBuildManageController;
});